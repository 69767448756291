import * as React from "react";
import { Rating } from "react-simple-star-rating";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

const Review = () => {
  const [setRating] = React.useState(0);
  const [reviews, setReviews] = React.useState([]);

  React.useEffect(() => {
    const url = `https://www.vipbari.xyz/reviews`;
    fetch(url, {})
      .then((res) => res.json())
      .then((data) => setReviews(data.slice(0, 6)));
  }, []);

  const handleRating = (rate) => {
    setRating(rate);
    // Some logic
  };

  return (
    <Container sx={{ my: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Typography fontWeight={600} variant="h6" gutterBottom>
          OUR HAPPY CLIENT
        </Typography>

        <div style={{ borderBottom: "1px solid #1976d2", width: "170px" }} />
      </Box>

      <Swiper
        className="py-3"
        spaceBetween={50}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        {reviews.map((review, _id) => (
          <SwiperSlide key={_id}>
            <Card
              sx={{ height: "18rem", p: 2 }}
              variant="outlined"
              align="center"
            >
              <div className="d-flex justify-content-center">
                <Avatar
                  alt="Remy Sharp"
                  src={review.img}
                  sx={{ height: 120, width: 120 }}
                />
              </div>
              <Typography sx={{ pt: 0.5 }} variant="subtitle2" component="div">
                {review.name}
              </Typography>

              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  {review.comment.slice(0, 80)}..
                </Typography>
                <Rating onClick={handleRating} ratingValue={review.ratting} />
              </CardContent>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default Review;
