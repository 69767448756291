import React from "react";
import NewArrivals from "../../ProductCategory/NewArrivals";
import ProductCategories from "../../ProductCategory/ProductCategories";
import LatestCollection from "../../Seller/LatestCollection";
import Seller from "../../Seller/Seller";
import Footer from "../../Shared/Footer/Footer";
import Navigation from "../../Shared/Navigation/Navigation";
import Shipping from "../../Shipping/Shipping";
import About from "../About/About";
import Banner from "../Banner/Banner";
import Product from "../Product/Product";
import Review from "../Review/Review";
import Property from "../../ProductCategory/Property/Property";
import BeautyProduct from "../../ProductCategory/BeautyProduct/BeautyProduct";
import HomeBuildings from "../../ProductCategory/HomeBuildings/HomeBuildings";

const Home = () => {
  return (
    <>
      <Navigation />
      <Banner />
      <ProductCategories />
      <Seller />
      <NewArrivals />
      <LatestCollection />
      <Property />
      <BeautyProduct />
      <HomeBuildings />
      <Shipping />
      {/* <Product /> */}
      <Review />
      {/* <About /> */}
      <Footer />
    </>
  );
};

export default Home;
