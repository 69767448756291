import React from "react";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <footer
      className="text-center text-lg-start text-white"
      style={{ backgroundColor: "#45526e" }}
    >
      <div className="container p-4 pb-0">
        <section className="">
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">
                Company name
              </h6>
              <p>
                Here you can use rows and columns to organize your footer
                content. Lorem ipsum dolor sit amet, consectetur adipisicing
                elit.
              </p>
            </div>

            <hr className="w-100 clearfix d-md-none" />

            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">Products</h6>
              <p>
                <a href="/" className="text-white text-decoration-none">
                  MDBootstrap
                </a>
              </p>
              <p>
                <a href="/" className="text-white text-decoration-none">
                  MDWordPress
                </a>
              </p>
              <p>
                <a href="/" className="text-white text-decoration-none">
                  BrandFlow
                </a>
              </p>
              <p>
                <a href="/" className="text-white text-decoration-none">
                  Bootstrap Angular
                </a>
              </p>
            </div>

            <hr className="w-100 clearfix d-md-none" />

            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">
                Useful links
              </h6>
              <p>
                <a href="/" className="text-white text-decoration-none">
                  Your Account
                </a>
              </p>
              <p>
                <a href="/" className="text-white text-decoration-none">
                  Become an Affiliate
                </a>
              </p>
              <p>
                <a href="/" className="text-white text-decoration-none">
                  Shipping Rates
                </a>
              </p>
              <p>
                <a href="/" className="text-white text-decoration-none">
                  Help
                </a>
              </p>
            </div>

            <hr className="w-100 clearfix d-md-none" />

            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
              <h6 className="text-uppercase mb-4 font-weight-bold">Contact</h6>
              <p>
                <i className="fas fa-home mr-3"></i> New York, NY 10012, US
              </p>
              <p>
                <i className="fas fa-envelope mr-3"></i> info@gmail.com
              </p>
              <p>
                <i className="fas fa-phone mr-3"></i> + 01 234 567 88
              </p>
              <p>
                <i className="fas fa-print mr-3"></i> + 01 234 567 89
              </p>
            </div>
          </div>
        </section>

        <hr className="my-3" />

        <section className="p-3 pt-0">
          <div className="row d-flex align-items-center">
            <div className="col-md-7 col-lg-8 text-center text-md-start">
              <div className="p-3">
                © 2023 Copyright:
                <a
                  className="text-white px-2 text-decoration-none"
                  href="https://vipbari.com/"
                >
                  Vipbari
                </a>
              </div>
            </div>

            <div className="col-md-5 col-lg-4 ml-lg-0 text-center text-md-end">
              <a
                href="/"
                className="btn btn-outline-light btn-floating m-1 text-white"
                role="button"
              >
                <FacebookRoundedIcon />
              </a>

              <a
                href="/"
                className="btn btn-outline-light btn-floating m-1 text-white"
                role="button"
              >
                <TwitterIcon />
              </a>

              <a
                href="/"
                className="btn btn-outline-light btn-floating m-1 text-white"
                role="button"
              >
                <InstagramIcon />
              </a>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
