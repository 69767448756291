import React, { useEffect, useState } from "react";
import "./SingleProduct.css";
import Seller from "../../Pages/Seller/Seller";
import Navigation from "../../Pages/Shared/Navigation/Navigation";
import Footer from "../../Pages/Shared/Footer/Footer";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ReactImageZoom from "react-image-zoom";
import { Row, Col, Container } from "react-bootstrap";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import axios from "axios";
import { useParams } from "react-router-dom";

const SingleProduct = () => {
  const [increase, setIncrease] = useState(1);
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { name } = useParams();

  const handleIncrease = () => {
    let total = increase + increase;
    setIncrease(total);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      const res = await axios.get(
        `https://uninterested-suit-slug.cyclic.app/products/search/${name}`
      );
      setProducts(res.data.slice(0, 1));
      setLoading(false);
    };

    fetchProducts();
  }, [name]);

  if (isLoading) return <p>Loading...</p>;

  return (
    <>
      <Navigation />
      <Container className="container my-5 py-4">
        {products.map((product, idx) => (
          <Row key={idx} className="justify-content-center">
            <Col xs={12} md={5}>
              <div className="main-img">
                <ReactImageZoom
                  {...{
                    height: 380,
                    width: 475,
                    zoomWidth: 500,
                    img: `${product.imageUrl}`,
                    className: "img-fluid border",
                  }}
                />
              </div>
            </Col>
            <Col xs={12} md={7} className="text-start">
              <div className="main-description px-2">
                <div className="category text-bold">
                  Category: {product.category}
                </div>
                <div className="product-title text-bold my-3">
                  {product.name}
                </div>

                <div className="price-area my-4">
                  {product.discountPercent && (
                    <p className="old-price mb-1">
                      <del>
                        <i
                          class="fa-solid fa-bangladeshi-taka-sign"
                          style={{ fontSize: "14px" }}
                        />{" "}
                        {product.marketPrice}
                      </del>{" "}
                      <span className="old-price-discount text-danger">
                        ({product.discountPercent}% off)
                      </span>
                    </p>
                  )}

                  <p className="new-price text-bold mb-1">
                    <i
                      class="fa-solid fa-bangladeshi-taka-sign"
                      style={{ fontSize: "30px" }}
                    />{" "}
                    {product.price}
                  </p>
                  <p className="text-secondary mb-1">
                    (Additional tax may apply on checkout)
                  </p>
                </div>

                <Row className="quantity">
                  <Col md={3} className="d-flex">
                    <IconButton color="primary" aria-label="add">
                      <RemoveIcon />
                    </IconButton>
                    <input
                      type="number"
                      className="form-control"
                      id="cart_quantity"
                      value={increase}
                      min="1"
                      max="5"
                      name="cart_quantity"
                    />
                    <IconButton
                      onClick={() => handleIncrease()}
                      color="primary"
                      aria-label="add"
                    >
                      <AddIcon />
                    </IconButton>
                  </Col>
                </Row>

                <div className="buttons d-flex my-3">
                  <div className="block">
                    <a href="#" className="shadow btn custom-btn ">
                      Wishlist
                    </a>
                  </div>
                  <div className="block">
                    <a href="/checkout" className="shadow btn custom-btn">
                      Add to cart
                    </a>
                  </div>
                </div>
              </div>

              <div className="product-details my-4">
                <p className="details-title text-color mb-1">Product Details</p>
                <p className="description">{product.description}</p>
              </div>

              <Row className="questions bg-light p-3">
                <Col md={1} className="icon">
                  <QuestionAnswerIcon color="info" sx={{ fontSize: "30px" }} />
                </Col>
                <div md={11} className="text">
                  Have a question about our products at E-Store? Feel free to
                  contact our representatives via live chat or email.
                </div>
              </Row>

              <div className="delivery my-4">
                <p className="font-weight-bold mb-0">
                  <span>
                    <i className="fa-solid fa-truck"></i>
                  </span>{" "}
                  <b>Delivery done in 3 days from date of purchase</b>{" "}
                </p>
                <p className="text-secondary">
                  Order now to get this product delivery
                </p>
              </div>
              <div className="delivery-options my-4">
                <p className="font-weight-bold mb-0">
                  <span>
                    <i className="fa-solid fa-filter"></i>
                  </span>{" "}
                  <b>Delivery options</b>{" "}
                </p>
                <p className="text-secondary">View delivery options here</p>
              </div>
            </Col>
          </Row>
        ))}
      </Container>

      <div className="container similar-products my-4">
        <hr />
        <p className="display-4">Similar Products</p>

        <div>
          <Seller />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SingleProduct;
