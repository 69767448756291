import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Container,
  Box,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Link } from "react-router-dom";
import axios from "axios";

const Property = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const res = await axios.get(
        "https://uninterested-suit-slug.cyclic.app/products/category/Property & Land"
      );
      setProducts(res.data);
    };
    fetchProducts();
  }, []);

  return (
    <Container sx={{ pt: 7 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <div className="d-flex justify-content-between">
          <Typography fontWeight={600} variant="h6" gutterBottom>
            Property & Land
          </Typography>
          <Button
            sx={{ textTransform: "none", my: 1 }}
            variant="contained"
            size="small"
            color="info"
          >
            View More
          </Button>
        </div>

        <div style={{ borderBottom: "1px solid #1976d2", width: "145px" }} />
      </Box>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        spaceBetween={20}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        {AllCategories.map((item, idx) => (
          <SwiperSlide key={idx}>
            <Link to={`/single/${item.name}`} className="text-decoration-none">
              <Card sx={{ mt: 1, mb: 5, height: "300px" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height={140}
                    image={item.img}
                    alt="title"
                  />

                  <CardContent
                    sx={{ justifyContent: "flex-start", textAlign: "center" }}
                  >
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      {item.name}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {item.details.slice(0, 40)}...
                    </Typography>

                    <Typography variant="body2" color="text.secondary">
                      {item.offer && (
                        <span className="text-danger">-{item.offer}%</span>
                      )}{" "}
                      $ {item.price}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      List Price:{" "}
                      <span style={{ textDecoration: "line-through" }}>
                        ${item.listPrice}.00
                      </span>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default Property;

const AllCategories = [
  {
    img: "https://assets-news.housing.com/news/wp-content/uploads/2022/03/28143140/Difference-between-flat-and-apartment.jpg",
    name: "Apartment/Flats",
    details: "Gulshan 02 , Dhaka",
    price: 7600000,
    offer: 25,
    listPrice: 9600000,
  },
  {
    img: "https://mediacdn.99acres.com/media1/20789/0/415780450M-1680512753339.jpg",
    name: "Apartment",
    details:
      "Visit This 1515 Sq Ft Residence Is Vacant For Sale In Jomidar Goli, Maghbazar",
    price: 10000000,
    offer: 25,
    listPrice: 15000000,
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOtoMF8k10HpEItHY6BCpa-DJRgu_flNiAzQ&usqp=CAU",
    name: "Apartment",
    details:
      "Apartment Block of 8 Units of 2 Bedroom Flats - Preston House Plans",
    price: 11000000,
    offer: 20,
    listPrice: 13000000,
  },
  {
    img: "https://assets-news.housing.com/news/wp-content/uploads/2022/03/28143140/Difference-between-flat-and-apartment.jpg",
    name: "Apartment/Flats",
    details: "Gulshan 02 , Dhaka",
    price: 7600000,
    offer: 25,
    listPrice: 9500000,
  },

  {
    img: "https://mediacdn.99acres.com/media1/20789/0/415780450M-1680512753339.jpg",
    name: "Apartment",
    details:
      "Visit This 1515 Sq Ft Residence Is Vacant For Sale In Jomidar Goli, Maghbazar",
    price: 12000000,
    offer: 25,
    listPrice: 15000000,
  },
];
