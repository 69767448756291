import React from 'react';
import Navigation from '../../Shared/Navigation/Navigation';

const Payment = () => {
    return (
        <>
            <Navigation/>
            <h2>Payment system is cooking...</h2>
           
        </>
    );
};

export default Payment;