import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import AuthProvider from "./contexts/AuthProvider/AuthProvider";
import AddReview from "./Pages/Dashboard/AddReview/AddReview";
import Dashboard from "./Pages/Dashboard/Dashboard/Dashboard";
import MyOrders from "./Pages/Dashboard/MyOrders/MyOrders";
import Payment from "./Pages/Dashboard/Payment/Payment";
import Home from "./Pages/Home/Home/Home";
import Login from "./Pages/Login/Login/Login";
import PrivateRoute from "./Pages/Login/PrivateRoute/PrivateRoute";
import Registation from "./Pages/Login/Registation/Registation";
import Purchase from "./Pages/Purchase/Purchase";
import "bootstrap/dist/css/bootstrap.min.css";
import AllProducts from "./Component/AllProducts/AllProducts";
import "@fortawesome/fontawesome-free/css/all.css";
import SingleProduct from "./Component/SingleProduct/SingleProduct";
import CheckOut from "./Component/CheckOut/CheckOut";
import UserProfile from "./Pages/UserProfile/UserProfile";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route path="/home">
            <Home />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Registation />
          </Route>
          <Route path="/explore">
            {/* <Explore /> */}
            <AllProducts />
          </Route>

          <Route path="/single/:name">
            <SingleProduct />
          </Route>

          <Route path="/checkout">
            <CheckOut />
          </Route>

          <Route path="/profile">
            <UserProfile />
          </Route>

          <PrivateRoute path="/dashboard">
            <Dashboard />
          </PrivateRoute>
          <PrivateRoute path="/purchase">
            <Purchase />
          </PrivateRoute>
          <PrivateRoute path="/myorders">
            <MyOrders />
          </PrivateRoute>
          <PrivateRoute path="/addReview">
            <AddReview />
          </PrivateRoute>
          <PrivateRoute path="/payment">
            <Payment />
          </PrivateRoute>
          <Route exact path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
