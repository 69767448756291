import { Alert } from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const MakeAdmin = () => {
  const [toggleAdmin, setToggleAdmin] = useState(false);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const handleAdminSubmit = (e) => {
    const user = { email };
    fetch("https://www.vipbari.xyz/users/admin", {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user),
    }).then((res) => {
      res.json();
      if (res.status === 200) {
        setEmail("");
        setSuccess(true);
      } else {
        alert("Something wrong");
      }
    });

    e.preventDefault();
  };

  const handleOnBlur = (e) => {
    setEmail(e.target.value);
  };
  return (
    <>
      {toggleAdmin ? (
        <Row className="justify-content-center py-5" style={{ height: "60vh" }}>
          <Col md={6}>
            <form onSubmit={handleAdminSubmit}>
              <div className="mb-3">
                <label for="exampleInputEmail1" className="form-label">
                  Email Address
                </label>
                <input
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  type="email"
                  onBlur={handleOnBlur}
                />

                <div id="emailHelp" className="form-text">
                  Want to add another admin? Simply enter their email address
                  below, to join as an admin. Please ensure that you have
                  permission from the person you're adding, and that you enter
                  their email address correctly.
                </div>
              </div>

              <Button variant="primary" type="submit">
                Create
              </Button>
              <Button
                onClick={() => setToggleAdmin(false)}
                className="mx-2"
                variant="danger"
              >
                Back
              </Button>

              {success && (
                <Alert className="my-2" severity="success">
                  Make Admin successfully{" "}
                </Alert>
              )}
            </form>
          </Col>
        </Row>
      ) : (
        <Container style={{ height: "60vh" }}>
          <div className="actions pb-4 pt-2">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={() => setToggleAdmin(true)}
            >
              <span className="btn-label px-1">
                <i className="fa fa-plus" />
              </span>
              Add Admin
            </button>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Price</th>
                <th className="col-sm-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a>{` product.name`}</a>
                </td>
                <td>{`product.description`}</td>
                <td>
                  {`product.price`}
                  <span
                    className="glyphicon glyphicon-euro"
                    aria-hidden="true"
                  ></span>
                </td>
                <td>
                  <a className="btn btn-danger btn-sm">Delete</a>
                </td>
              </tr>
            </tbody>
          </table>
        </Container>
      )}
    </>
  );
};

export default MakeAdmin;
