import React from "react";
import { Avatar, Container, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import style from "./shipping.module.css";

const Shipping = () => {
  const shippings = [
    {
      name: "Money Back",
      title: "30 days money back gurantee",
      icon: "M",
    },
    {
      name: "SPECIAL SALE & SUPPORT",
      title: "Extra $5 off on all item",
      icon: "S",
    },
    { name: "Free Shipping", title: "Shipping on over 99$", icon: "F" },
    {
      name: "Social Sale & Support",
      title: "Extra 5$ off on all Item",
      icon: "S",
    },
  ];

  return (
    <Container sx={{ pt: 5 }}>
      <Swiper
        spaceBetween={20}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {shippings.map((shipping, idx) => (
          <SwiperSlide key={idx}>
            <Card
              className={`${style.cardColor} border d-flex justify-content-center flex-column align-items-center`}
              sx={{ py: 1 }}
            >
              <Avatar sx={{ bgcolor: "info.main" }}>{shipping.icon}</Avatar>
              <CardContent>
                <Typography variant="h6" component="div">
                  {shipping.name}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {shipping.title}
                </Typography>
              </CardContent>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default Shipping;

// slidesPerView={2}
