import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";

const ProductForm = ({ onBack }) => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [marketPrice, setMarketPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("name", name);
    formData.append("price", price);
    formData.append("marketPrice", marketPrice);
    formData.append("discountPercent", discount);
    formData.append("category", category);
    formData.append("description", description);
    formData.append("image", image);

    fetch("https://uninterested-suit-slug.cyclic.app/product", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        res.json();
        if (res.status === 201) {
          setName("");
          setPrice("");
          setMarketPrice("");
          setDiscount("");
          setCategory("");
          setDescription("");
          setImage(null);
          document.getElementById("image").value = "";
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Your work has been saved",
            showConfirmButton: false,
            timer: 1500,
          });
          onBack();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
      })

      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: { error },
          text: "Something went wrong!",
        });
      });
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col md={6}>
          <h2 className="mb-4 text-center border-bottom">Add new Product</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Name<span className="text-danger mx-1">*</span>
              </Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Name"
                required
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>
                    Price (BDT) <span className="text-danger mx-1">*</span>
                  </Form.Label>
                  <Form.Control
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    type="number"
                    placeholder="Price"
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Market price (BDT)</Form.Label>
                  <Form.Control
                    value={marketPrice}
                    onChange={(e) => setMarketPrice(e.target.value)}
                    type="number"
                    placeholder="Price"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Discount (%)</Form.Label>
                  <Form.Control
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    type="number"
                    placeholder="Price"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>
                    Category <span className="text-danger mx-1">*</span>
                  </Form.Label>
                  <Form.Select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    aria-label="Default select example"
                    required
                  >
                    <option value="">Select category</option>
                    <option value="Fashion">Fashion</option>
                    <option value="Household items">Household items</option>
                    <option value="Mobile/Tablet">Mobile/Tablet</option>
                    <option value="Health and wellness">
                      Health and wellness
                    </option>
                    <option value="Furniture and decor">
                      Furniture and decor
                    </option>
                    <option value="Home & Buildings">Home & Buildings</option>
                    <option value="Property & Land">Property & Land</option>
                    <option value="Beauty & Glamour">Beauty & Glamour</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                Description <span className="text-danger mx-1">*</span>
              </Form.Label>
              <Form.Control
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                as="textarea"
                rows={3}
              />
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>
                Image <span className="text-danger mx-1">*</span>
              </Form.Label>
              <Form.Control
                id="image"
                onChange={(e) => setImage(e.target.files[0])}
                type="file"
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Create
            </Button>

            <Button
              className="mx-2"
              onClick={onBack}
              variant="danger"
              type="submit"
            >
              Back
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductForm;
