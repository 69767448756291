import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./ManageAllOrders.css";

const ManageAllOrders = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const orders = [
    {
      id: 1150,
      imgSrc: "https://i.postimg.cc/d1bVjpHd/01.jpg",
      itemName: "Cheese Pizza",
      customerName: "Mark Willy",
      quantity: 5,
      price: 2590,
      status: "Confirmed",
      phone: "+123 9856047",
    },
    {
      id: 1151,
      imgSrc: "https://i.postimg.cc/13s4Tx9r/02.jpg",
      itemName: "Big Burger",
      customerName: "Mithila Zaman",
      quantity: 3,
      price: 1590,
      status: "Pending",
      phone: "+123 9856047",
    },
    {
      id: 1152,
      imgSrc: "https://i.postimg.cc/ncYhqdvj/03.jpg",
      itemName: "Chicken Pasta",
      customerName: "Siddikur Rahman",
      quantity: 5,
      price: 2590,
      status: "Confirmed",
      phone: "+123 9856047",
    },
    {
      id: 1153,
      imgSrc: "https://i.postimg.cc/3xtrcdf3/04.png",
      itemName: "Chicken Pizza",
      customerName: "Siddikur Rahman",
      quantity: 5,
      price: 2590,
      status: "Confirmed",
      phone: "+123 9856047",
    },
    {
      id: 1154,
      imgSrc: "https://i.postimg.cc/yNrdkk0L/05.jpg",
      itemName: "Big Burger",
      customerName: "Mithila Zaman",
      quantity: 3,
      price: 1590,
      status: "Pending",
      phone: "+123 9856047",
    },
    {
      id: 1155,
      imgSrc: "https://i.postimg.cc/8PVCJ8rg/06.png",
      itemName: "Chicken Pasta",
      customerName: "Siddikur Rahman",
      quantity: 5,
      price: 2590,
      status: "Confirmed",
      phone: "+123 9856047",
    },
  ];

  return (
    <div>
      <div class="list">
        <div class="heading">
          <h4>Order List</h4>
          <i class="fas fa-ellipsis-h"></i>
        </div>
        <form>
          <input type="search" placeholder="Invoice Search" />
          <input type="text" placeholder="Search by Title" />
          <input type="phone" placeholder="Search by Phone" />
          <input type="submit" value="Search" />
        </form>
        <div class="main">
          <input type="checkbox" />
          <span class="invoice">
            <span>Invoice</span>
          </span>
          <span class="image">
            <span>Image</span>
          </span>
          <span class="menu">
            <span>Menu</span>
            <i class="fas fa-sort-down fa-sm" />
          </span>
          <span class="customer">
            <span>Customer Name</span>
            <i class="fas fa-sort-down fa-sm" />
          </span>
          <span class="quantity">
            <span>Quantity</span>
          </span>
          <span class="amount">
            <span>Amount</span>
            <i class="fas fa-sort-down fa-sm"></i>
          </span>
          <span class="status">
            <span>Order Status</span>
            <i class="fas fa-sort-down fa-sm"></i>
          </span>
          <span class="phone">
            <span>Phone</span>
            <i class="fas fa-sort-down fa-sm"></i>
          </span>
        </div>
        <div className="items">
          {orders.map((order, idx) => (
            <div key={idx} className="item">
              <div className="check">
                <input type="checkbox" />
              </div>
              <span>#{order.id}</span>
              <img src={order.imgSrc} alt={order.itemName} />
              <span>{order.itemName}</span>
              <span>{order.customerName}</span>
              <span>{order.quantity}</span>
              <span>${order.price}</span>
              <button>{order.status}</button>
              <span>{order.phone}</span>
              <i onClick={handleClick} className="fas fa-ellipsis-h" />
            </div>
          ))}
        </div>

        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem className="text-warning" key="remove" onClick={handleClose}>
            Remove
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default ManageAllOrders;
