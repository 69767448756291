import React from "react";
import Navigation from "../Shared/Navigation/Navigation";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import "./Purchase.css";

const Purchase = () => {
  return (
    <>
      <Navigation />
      <section class="h-100 h-custom">
        <div class="container py-5 h-100 ">
          <div class="row g-2 justify-content-center">
            <div class="col-lg-7 p-3 border-right">
              <h5 class="mb-3">
                <a href="/" class="text-info text-decoration-none">
                  <i class="fas fa-long-arrow-alt-left me-2"></i>
                  Continue shopping
                </a>
              </h5>
              <hr />

              <div class="d-flex justify-content-between align-items-center mb-4">
                <div>
                  <p class="mb-1">Shopping cart</p>
                  <p class="mb-0">You have 4 items in your cart</p>
                </div>
                <div>
                  <p class="mb-0">
                    <span class="text-muted">Sort by:</span>{" "}
                    <a href="#!" class="text-body">
                      price <i class="fas fa-angle-down mt-1"></i>
                    </a>
                  </p>
                </div>
              </div>

              <div class="card-deck">
                {products.map((product, index) => (
                  <div class="card mb-3" key={index}>
                    <div class="card-body">
                      <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex flex-row align-items-center">
                          <div>
                            <img
                              src={product.image}
                              class="img-fluid rounded-3"
                              alt={product.name}
                              style={{ width: "65px" }}
                            />
                          </div>
                          <div class="ms-3">
                            <h5>{product.name}</h5>
                            <p class="small mb-0">{product.description}</p>
                          </div>
                        </div>
                        <div class="d-flex flex-row align-items-center">
                          <div style={{ width: "120px" }} className="d-flex">
                            <button class="btn btn-link px-2">
                              <i class="fas fa-minus"></i>
                            </button>
                            <input
                              min="0"
                              name="quantity"
                              type="number"
                              class="form-control form-control-sm"
                            />

                            <button class="btn btn-link px-2">
                              <i class="fas fa-plus"></i>
                            </button>
                          </div>
                          <div style={{ width: "80px" }}>
                            <h5 class="mb-0">
                              {" "}
                              <i class="fa-solid fa-bangladeshi-taka-sign" />{" "}
                              {product.price}
                            </h5>
                          </div>
                          <a href="#!" style={{ color: "#cecece" }}>
                            <i class="fas fa-trash-alt"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div class="col-lg-5 border h-50 p-3">
              <h5 className="text-info">Discount and Payment</h5>

              <div class="d-flex justify-content-between my-3">
                <h6 class="mb-2">
                  <ConfirmationNumberIcon color="warning" /> Voucher
                </h6>
                <p class="mb-2">No Applicable Voucher</p>
              </div>

              <div class="d-flex justify-content-between">
                <h6 class="mb-2">
                  <LocalOfferIcon color="warning" /> Promo Code
                </h6>
                <p class="mb-2">No Applicable Voucher</p>
              </div>

              <hr class="my-3" />

              <div class="d-flex justify-content-between">
                <h6 class="mb-2">Subtotal</h6>
                <p class="mb-2">
                  {" "}
                  <i class="fa-solid fa-bangladeshi-taka-sign" /> 4798.00
                </p>
              </div>

              <div class="d-flex justify-content-between">
                <h6 class="mb-2">Shipping</h6>
                <p class="mb-2">
                  {" "}
                  <i class="fa-solid fa-bangladeshi-taka-sign" /> 20.00
                </p>
              </div>

              <div class="d-flex justify-content-between mb-4">
                <h6 class="mb-2">Total(Incl. taxes)</h6>
                <p class="mb-2">
                  <i class="fa-solid fa-bangladeshi-taka-sign" /> 4818.00
                </p>
              </div>

              <div class="row p-3">
                <a
                  href="/checkout"
                  class="btn btn-warning w-100 btn-md fw-bold text-white"
                >
                  Checkout
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      ;
    </>
  );
};

export default Purchase;

const products = [
  {
    name: "Iphone 11 pro",
    image:
      "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-shopping-carts/img1.webp",
    description: "256GB, Navy Blue",
    price: 900,
  },
  {
    name: "Samsung galaxy Note 10",
    image:
      "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-shopping-carts/img2.webp",
    description: "256GB, Navy Blue",
    price: 900,
  },
  {
    name: "Canon EOS M50",
    image:
      "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-shopping-carts/img3.webp",
    description: "Onyx Black",
    price: 1199,
  },
  {
    name: "MacBook Pro",
    image:
      "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-shopping-carts/img4.webp",
    description: "1TB, Graphite",
    price: 1799,
  },
];
