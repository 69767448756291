import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import logo from "../../../images/vipbari.png";
import { Link, NavLink } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { Avatar } from "@mui/material";

const drawerWidth = 240;

function Navigation(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { admin, user, logout } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <div className="d-flex align-items-center justify-content-center">
        <img src={logo} style={{ height: "50px" }} alt="brand logo" />
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            fontFamily: "monospace",
            fontWeight: 700,
            fontSize: 25,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          bari.com
        </Typography>
      </div>

      <Divider sx={{ borderBottom: "1px solid gray" }} />
      <List className="mt-4">
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            className="d-flex flex-column"
          >
            <Link style={{ textDecoration: "none" }} to="/home">
              <Button>Home</Button>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/explore">
              <Button>Explore</Button>
            </Link>
            {user?.email ? (
              <>
                <NavLink style={{ textDecoration: "none" }} to="/dashboard">
                  <Button color="inherit">Dashboard</Button>
                </NavLink>
                <Button
                  size="small"
                  variant="contained"
                  onClick={logout}
                  color="inherit"
                >
                  Logout
                </Button>
              </>
            ) : (
              <NavLink style={{ textDecoration: "none" }} to="/login">
                <Button color="inherit">Login</Button>
              </NavLink>
            )}
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }} className="my-2">
      <AppBar component="nav" color="transparent" sx={{ boxShadow: "none" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>

          <img src={logo} style={{ height: "50px" }} alt="brand logo" />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              fontFamily: "monospace",
              fontWeight: 700,
              fontSize: 25,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            bari.com
          </Typography>

          <Box
            sx={{ display: { xs: "none", sm: "block" }, flexGrow: 1 }}
            className="text-end"
          >
            <Link style={{ textDecoration: "none" }} to="/home">
              <Button>Home</Button>
            </Link>
            <Link style={{ textDecoration: "none" }} to="/explore">
              <Button>Explore</Button>
            </Link>
            {user?.email ? (
              <>
                {admin && (
                  <NavLink
                    style={{ textDecoration: "none", color: "white" }}
                    to="/dashboard"
                  >
                    <Button>Dashboard</Button>
                  </NavLink>
                )}
                <Button onClick={logout}>Logout</Button>
              </>
            ) : (
              <NavLink
                style={{ textDecoration: "none", color: "white" }}
                to="/login"
              >
                <Button>Login</Button>
              </NavLink>
            )}
            {user?.email && (
              <IconButton sx={{ p: 0 }}>
                <Avatar
                  sx={{ width: "35px", height: "35px" }}
                  src={user.photoURL}
                  alt={user?.displayName}
                />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

Navigation.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navigation;
