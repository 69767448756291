import React, { useEffect, useState } from "react";
import axios from "axios";

const ProductAdd = ({ addProduct }) => {
  const [allProducts, setAllProducts] = useState([]);
  console.log({ allProducts });

  const fetchProducts = async () => {
    const res = await axios.get(
      "https://uninterested-suit-slug.cyclic.app/products"
    );
    setAllProducts(res.data);
  };

  useEffect(() => {
    fetchProducts();
  }, []);
  return (
    <div>
      <div className="alert alert-warning">
        <strong>New Product Form!</strong> Add a New Product to Your Online
        Store
      </div>

      <div className="actions pb-4 pt-2">
        <button
          onClick={addProduct}
          type="button"
          className="btn btn-outline-secondary"
        >
          <span className="btn-label px-1">
            <i className="fa fa-plus" />
          </span>
          Add Product
        </button>
      </div>

      <div className="row">
        <div className="form-group col-sm-3">
          <label for="search-element">Product name</label>
          <input className="form-control" id="search-element" requred />
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Price</th>
            <th className="col-sm-2">Actions</th>
          </tr>
        </thead>
        {allProducts.map((product, idx) => (
          <tbody key={idx}>
            <tr>
              <td>
                <a>{product.name}</a>
              </td>
              <td>{product.description.slice(0, 70)}</td>
              <td>{product.price} BDT</td>
              <td>
                <a className="btn btn-warning btn-sm mx-1">Edit</a>
              </td>
            </tr>
          </tbody>
        ))}
      </table>
    </div>
  );
};

export default ProductAdd;
