import { Typography } from "@mui/material";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const ManageAccount = () => {
  return (
    <Container>
      <Typography gutterBottom variant="h6" component="div">
        Manage My Account
      </Typography>
      <Row className="g-4">
        <Col className="bg-white p-3 mx-1">
          <Typography
            gutterBottom
            variant="subtitle2"
            component="div"
            fontSize={14}
          >
            Personal Profile | <span className="text-info">Edit</span>
          </Typography>
          <Typography
            gutterBottom
            variant="caption"
            component="div"
            fontSize={14}
          >
            Md.Nazirul Islam
          </Typography>
          <Typography
            gutterBottom
            variant="caption"
            component="div"
            fontSize={14}
          >
            shanto2193@gmail.com
          </Typography>
        </Col>
        <Col className="bg-white p-3">
          <Typography gutterBottom variant="subtitle1" component="div">
            Address Book
          </Typography>
          <Typography
            gutterBottom
            variant="subtitle2"
            component="div"
            fontSize={14}
          >
            Md.Nazirul Islam
          </Typography>
          <Typography
            gutterBottom
            variant="caption"
            component="div"
            fontSize={14}
          >
            House-15, Road-01, Block-G, Mirpur-2 Dhaka - Dhaka - North - Mirpur
            Section 2 (+880) 1788780007
          </Typography>
        </Col>
      </Row>
      <Row className="bg-white g-4 p-3 mt-3">Recent Orders</Row>
    </Container>
  );
};

export default ManageAccount;
