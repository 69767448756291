const firebaseConfig = {
  // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain:  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  //-----------------------------------------------------------------------//
  // appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // apiKey: "AIzaSyCiUFaz7nli9aQzSLxoB8hjCW418P3Cuzo",
  // authDomain: "doctors-portal-b508a.firebaseapp.com",
  // projectId: "doctors-portal-b508a",
  // storageBucket: "doctors-portal-b508a.appspot.com",
  // messagingSenderId: "428165940338",
  // appId: "1:428165940338:web:d00a05f43f187c05956eeb"
  //---------------------------------------------------------------------//

  apiKey: "AIzaSyCWkKbIcoYOLC2raCN5ZJp1KeGoxrLgSb4",
  authDomain: "vip-bari.firebaseapp.com",
  projectId: "vip-bari",
  storageBucket: "vip-bari.appspot.com",
  messagingSenderId: "891400499025",
  appId: "1:891400499025:web:9435db181ea66bd6962d70",
  measurementId: "G-6LKE3DHK8M"
};

export default firebaseConfig;
