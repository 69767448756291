import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import { Autoplay } from "swiper";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";
import { Button, Typography, Container } from "@mui/material";
import shoe1 from "../../../images/product/shoe1.png";
import shoe2 from "../../../images/product/shoe2.png";
import headphone from "../../../images/product/headphone.png";
import offer1 from "../../../images/product/offer1.png";
import offer2 from "../../../images/product/offer2.png";
import { Row, Col } from "react-bootstrap";
const offerbg1 = {
  backgroundImage: `url(${offer1})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
const offerbg2 = {
  backgroundImage: `url(${offer2})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const Banner = () => {
  const products = [
    { image: shoe1, title: "shoe1" },
    { image: shoe2, title: "shoe2" },
    { image: headphone, title: "headphone" },
  ];

  return (
    <Container sx={{ my: 8 }}>
      <Row className="d-flex justify-content-center align-items-center g-3">
        <Col md={8}>
          <Paper
            elevation={0}
            sx={{
              backgroundColor: " rgba(229, 233, 236, 255)",
              p: 1.5,
            }}
          >
            <Row className="d-flex justify-content-center align-items-center">
              <Col md={8}>
                <Typography
                  varient="h5"
                  fontWeight={400}
                  fontSize="20px"
                  sx={{ letterSpacing: "5px", textTransform: "uppercase" }}
                >
                  {" "}
                  Top Brands{" "}
                </Typography>
                <Typography
                  varient="h4"
                  fontWeight={500}
                  fontSize="30px"
                  sx={{ letterSpacing: "5px", textTransform: "uppercase" }}
                >
                  {" "}
                  New Collection{" "}
                </Typography>
                <Typography variant="h5" sx={{ letterSpacing: "20px" }}>
                  {" "}
                  Sale 40% off{" "}
                </Typography>
                <Typography variant="h6" fontWeight={50}>
                  {" "}
                  Te obtinuit ut adepto satis somno{" "}
                </Typography>
                <Button>Show Now</Button>
              </Col>
              <Col md={4}>
                <Swiper
                  className="mySwiper "
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                >
                  {products.map((product, idx) => (
                    <SwiperSlide
                      key={idx}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <img
                        height={250}
                        width={150}
                        src={product.image}
                        alt="title"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Col>
            </Row>
          </Paper>
        </Col>
        <Col md={4}>
          <Box
            style={offerbg1}
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              p: 1,
            }}
          >
            <Typography variant="h5" sx={{ fontSize: "15px" }}>
              25% discount
            </Typography>
            <Typography variant="h5" sx={{ fontSize: "30px" }}>
              Fashion Sale
            </Typography>
            <Typography variant="h5" sx={{ fontSize: "15px" }}>
              Only select item only
            </Typography>
            <Button sx={{ display: "flex", justifyContent: "flex-start" }}>
              Buy Now
            </Button>
          </Box>

          <Box
            style={offerbg2}
            sx={{
              mt: 2,
              display: "flex",
              flexDirection: "column",
              textAlign: "right",
              justifyContent: "center",
              p: 1,
            }}
          >
            <Box sx={{ height: "110px" }}>
              <Typography>Mens Fashion</Typography>
              <Typography>Only select item only</Typography>
              <Button>Buy Now</Button>
            </Box>
          </Box>
        </Col>
      </Row>
    </Container>
  );
};

export default Banner;
