import React from "react";
import Navigation from "../Shared/Navigation/Navigation";
import Footer from "../Shared/Footer/Footer";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import "./UserProfile.css";
import ManageAccount from "../../Component/UserDashboard/ManageAccount/ManageAccount";

const UserProfile = () => {
  return (
    <>
      <Navigation />
      <div style={{ backgroundColor: "#eff0f4" }}>
        <Container className="mt-5 py-4 ">
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Row>
              <Col sm={3}>
                <Nav
                  className="flex-column"
                  style={{ color: "#000000 !important" }}
                >
                  <Nav.Item>
                    <Nav.Link className="fw-bold" eventKey="Account">
                      Manage My Account
                    </Nav.Link>
                    <Nav.Item className="mx-3">
                      <Nav.Link eventKey="Profile">My Profile</Nav.Link>
                      <Nav.Link className="p-0 mx-3" eventKey="Address">
                        Address Book
                      </Nav.Link>
                      <Nav.Link eventKey="PaymentOption">
                        Payment Options
                      </Nav.Link>
                    </Nav.Item>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="fw-bold" eventKey="Orders">
                      My Orders
                    </Nav.Link>
                    <Nav.Item className="mx-3">
                      <Nav.Link eventKey="Returns">My Returns</Nav.Link>
                      <Nav.Link className="p-0 mx-3" eventKey="Cancellations">
                        My Cancellations
                      </Nav.Link>
                    </Nav.Item>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link className="fw-bold " eventKey="Reviews">
                      My Reviews
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link className="fw-bold p-0 mx-3" eventKey="Favorites">
                      Favorites List
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="Account">
                    <ManageAccount />
                  </Tab.Pane>
                  <Tab.Pane eventKey="Address">Address</Tab.Pane>
                  <Tab.Pane eventKey="Profile">Profile</Tab.Pane>
                  <Tab.Pane eventKey="PaymentOption">PaymentOption</Tab.Pane>

                  <Tab.Pane eventKey="Orders">Orders</Tab.Pane>
                  <Tab.Pane eventKey="Returns">Returns</Tab.Pane>
                  <Tab.Pane eventKey="Cancellations">Cancellations</Tab.Pane>

                  <Tab.Pane eventKey="Reviews">Reviews</Tab.Pane>
                  <Tab.Pane eventKey="Favorites"> Favorites List</Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default UserProfile;
