import React from "react";
import Pagination from "@mui/material/Pagination";

const PaginationPage = ({
  productsPerPage,
  totalPosts,
  paginate,
  currentPage,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / productsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="d-flex justify-content-center my-4">
      <Pagination
        size="small"
        count={pageNumbers.length}
        page={currentPage}
        onChange={(event, value) => paginate(value)}
      />
    </div>
  );
};

export default PaginationPage;
