import React, { useState } from "react";
import ProductAdd from "../../../Component/ProductAdd/ProductAdd";
import ProductForm from "../../../Component/ProductForm/ProductForm";

const AddProduct = () => {
  const [showFirstComponent, setShowFirstComponent] = useState(true);

  function handleProduct() {
    setShowFirstComponent(false);
  }

  function handleBack() {
    setShowFirstComponent(true);
  }
  return (
    <div>
      {showFirstComponent ? (
        <ProductAdd addProduct={handleProduct} />
      ) : (
        <ProductForm onBack={handleBack} />
      )}
    </div>
  );
};

export default AddProduct;
