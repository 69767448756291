import { Button, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Category1 from "./Category1";
import axios from "axios";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const NewArrivals = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  console.log({ selectedTab });
  const [selectedCategory, setSelectedCategory] = React.useState(0);

  function handleChange(event, newValue) {
    setSelectedTab(newValue);
    setSelectedCategory(categories[newValue]); // Update selected category based on the selected tab
  }

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const fetchProducts = async () => {
      const res = await axios.get(
        "https://uninterested-suit-slug.cyclic.app/categories"
      );
      setCategories(res.data);
    };

    fetchProducts();
  }, []);

  return (
    <Container sx={{ mt: 5 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <div className="d-flex justify-content-between">
          <Typography fontWeight={600} variant="h6" gutterBottom>
            NEW ARRIVALS
          </Typography>
          <Button
            sx={{ textTransform: "none", my: 1 }}
            variant="contained"
            size="small"
            color="info"
          >
            View More Arrivals
          </Button>
        </div>
        <div style={{ borderBottom: "1px solid #1976d2", width: "160px" }} />
      </Box>

      <Box sx={{ width: "100%", pt: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons="auto"
            centered
          >
            {categories.map((category, index) => (
              <Tab
                sx={{ textTransform: "none" }}
                key={category}
                label={category}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>

        {categories.map((category, index) => (
          <TabPanel
            key={index}
            value={selectedTab}
            index={index}
            category={category}
          >
            <Category1 category={selectedCategory} />
          </TabPanel>
        ))}
      </Box>
    </Container>
  );
};

export default NewArrivals;
