import React, { useEffect, useState } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";

const Category1 = (category) => {
  const [products, setProducts] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const encodedCategory = encodeURIComponent(
    (category || {}).category || "Fashion"
  );

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      const category = encodedCategory ? encodedCategory : "Fashion";
      const res = await axios.get(
        `https://uninterested-suit-slug.cyclic.app/products/category/${category}`
      );
      setProducts(res.data);
      setLoading(false);
    };

    fetchProducts();
  }, [category.category, encodedCategory]);

  if (isLoading) return <p>Loading...</p>;

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      {products.map((item, idx) => (
        <Grid item xs={4} sm={4} md={3} key={idx}>
          <Link to={`/single/${item.name}`} className="text-decoration-none">
            <Card sx={{ maxWidth: 345, height: 260 }}>
              <CardActionArea>
                <a href="/single" className="text-decoration-none">
                  <CardMedia
                    style={{ objectFit: "contain", width: "100%" }}
                    component="img"
                    height={128}
                    image={item.imageUrl}
                    alt="title"
                  />
                </a>
                <CardContent
                  sx={{ justifyContent: "flex-start", textAlign: "center" }}
                >
                  <Typography gutterBottom variant="subtitle2" component="div">
                    {item.name.length > 40
                      ? `${item.name.slice(0, 40)}...`
                      : item.name}
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    {item.discountPercent && (
                      <span className="text-danger">
                        -{item.discountPercent}%
                      </span>
                    )}{" "}
                    $ {item.price}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    List Price:{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      ${item.marketPrice}.00
                    </span>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default Category1;
