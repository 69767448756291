import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Container,
  Box,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import axios from "axios";
import { Link } from "react-router-dom";

const BeautyProduct = () => {
  const [products, setProducts] = useState([]);
  console.log(process.env.baseURL);

  useEffect(() => {
    const fetchProducts = async () => {
      const res = await axios.get(
        `https://uninterested-suit-slug.cyclic.app/products/category/Beauty & Glamour`
      );
      setProducts(res.data);
    };
    fetchProducts();
  }, []);

  return (
    <Container sx={{ pt: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <div className="d-flex justify-content-between">
          <Typography fontWeight={600} variant="h6" gutterBottom>
            Beauty & Glamour
          </Typography>
          <Button
            sx={{ textTransform: "none", my: 1 }}
            variant="contained"
            size="small"
            color="info"
          >
            View More
          </Button>
        </div>

        <div style={{ borderBottom: "1px solid #1976d2", width: "160px" }} />
      </Box>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        spaceBetween={20}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        {products.map((item, idx) => (
          <SwiperSlide key={idx}>
            <Link to={`/single/${item.name}`} className="text-decoration-none">
              <Card sx={{ mt: 1, mb: 5, height: "300px" }}>
                <CardActionArea>
                  <CardMedia
                    sx={{ objectFit: "contain" }}
                    component="img"
                    height={135}
                    image={item?.imageUrl}
                    alt="title"
                  />

                  <CardContent
                    sx={{ justifyContent: "flex-start", textAlign: "center" }}
                  >
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      component="div"
                    >
                      {item.name.slice(0, 30)}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      {item.description.slice(0, 40)}...
                    </Typography>

                    {item.discountPercent && (
                      <Typography variant="body2" color="text.secondary">
                        <span className="text-danger">
                          (-{item.discountPercent}%)
                        </span>{" "}
                        <del>
                          <i
                            class="fa-solid fa-bangladeshi-taka-sign"
                            style={{ fontSize: "12px" }}
                          />{" "}
                          {item.marketPrice}.00
                        </del>
                      </Typography>
                    )}
                    <Typography variant="subtitle2" color="text.primary">
                      <i
                        class="fa-solid fa-bangladeshi-taka-sign"
                        style={{ fontSize: "14px" }}
                      />{" "}
                      {""}
                      {item?.price}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  );
};

export default BeautyProduct;

const AllCategories = [
  {
    img: "https://media.glamour.com/photos/6234cb2cf611320ea4cba71a/master/w_320%2Cc_limit/Glamour-Beauty-Awards-Readers-Faves-8.png",
    name: "The Glamour Beauty",
    details:
      "The Glamour Beauty Awards Readers' Choice Winners of 2022 | Glamour",
    price: 850,
    offer: 25,
    listPrice: 1200,
  },
  {
    img: "https://media.vogue.fr/photos/5d0a105703183a8d401f3ac0/master/w_1600%2Cc_limit/182855_estee_lauder_advance.jpg",
    name: "Vogue France",
    details:
      "The six fastest selling beauty products in the world | Vogue France",
    price: 1250,
    offer: 15,
    listPrice: 1850,
  },
  {
    img: "https://kireibd.com/wp-content/uploads/2020/12/biore-uv-watery-essence-sunscreen.png",
    name: "Biore UV Aqua Rich Watery Essence Sunscreen 50 ml",
    details:
      "Japanese Beauty Products Store in Bangladesh – Authentic products from Japan",
    price: 1100,
    offer: 10,
    listPrice: 1300,
  },
  {
    img: "https://media.glamour.com/photos/6234cb2cf611320ea4cba71a/master/w_320%2Cc_limit/Glamour-Beauty-Awards-Readers-Faves-8.png",
    name: "The Glamour Beauty",
    details:
      "The Glamour Beauty Awards Readers' Choice Winners of 2022 | Glamour",
    price: 850,
    offer: 25,
    listPrice: 1200,
  },
  {
    img: "https://media.vogue.fr/photos/5d0a105703183a8d401f3ac0/master/w_1600%2Cc_limit/182855_estee_lauder_advance.jpg",
    name: "Vogue France",
    details:
      "The six fastest selling beauty products in the world | Vogue France",
    price: 1250,
    offer: 15,
    listPrice: 1850,
  },
];
