export const AllCategories = [
  {
    img: "https://m.media-amazon.com/images/I/71sBwQu6U8L._AC_UL1500_.jpg",
    name: "Anne Klein",
    details: "Women's Genuine Diamond Dial Bracelet Watch",
    price: 32.59,
    offer: 57,
    listPrice: 75,
  },
  {
    img: "https://m.media-amazon.com/images/I/71sBwQu6U8L._AC_UL1500_.jpg",
    name: "Anne Klein",
    details: "Women's Genuine Diamond Dial Bracelet Watch",
    price: 32.59,
    offer: 57,
    listPrice: 75,
  },
  {
    img: "https://media.wired.com/photos/6154ba291b38af32f7638ffd/4:3/w_1747,h_1310,c_limit/Gear-Barefoot-Shoes-Freet-Tanga-SOURCE-Freet.jpg",
    name: "Anne Klein",
    details: "Women's Genuine Diamond Dial Bracelet Watch",
    price: 32.59,
    offer: 57,
    listPrice: 75,
  },
  {
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTkcbKQVczmYsT73ra4Nl9BlmcNx3LpnSVUfeKPoptc6xmOFZjToboyfDAysQegjCEf5s&usqp=CAU",
    name: "Anne Klein",
    details: "Women's Genuine Diamond Dial Bracelet Watch",
    price: 32.59,
    offer: 57,
    listPrice: 75,
  },
  {
    img: "https://m.media-amazon.com/images/I/71sBwQu6U8L._AC_UL1500_.jpg",
    name: "Anne Klein",
    details: "Women's Genuine Diamond Dial Bracelet Watch",
    price: 32.59,
    offer: 57,
    listPrice: 75,
  },
  {
    img: "https://media.wired.com/photos/6154ba291b38af32f7638ffd/4:3/w_1747,h_1310,c_limit/Gear-Barefoot-Shoes-Freet-Tanga-SOURCE-Freet.jpg",
    name: "Anne Klein",
    details: "Women's Genuine Diamond Dial Bracelet Watch",
    price: 32.59,
    offer: 57,
    listPrice: 75,
  },
  {
    img: "https://m.media-amazon.com/images/I/71sBwQu6U8L._AC_UL1500_.jpg",
    name: "Anne Klein",
    details: "Women's Genuine Diamond Dial Bracelet Watch",
    price: 32.59,
    offer: 57,
    listPrice: 75,
  },
  {
    img: "https://media.wired.com/photos/6154ba291b38af32f7638ffd/4:3/w_1747,h_1310,c_limit/Gear-Barefoot-Shoes-Freet-Tanga-SOURCE-Freet.jpg",
    name: "Anne Klein",
    details: "Women's Genuine Diamond Dial Bracelet Watch",
    price: 32.59,
    offer: 57,
    listPrice: 75,
  },
];
