import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from "@mui/material";
import Navigation from "../../Pages/Shared/Navigation/Navigation";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CategoryIcon from "@mui/icons-material/Category";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import KitchenIcon from "@mui/icons-material/Kitchen";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import { AllCategories } from "../../Data/Product";
import Shipping from "../../Pages/Shipping/Shipping";
import Footer from "../../Pages/Shared/Footer/Footer";
import PaginationPage from "../../Pagination/Pagination";

const categories = [
  { label: "Fashion", icon: <CategoryIcon /> },
  { label: "Electronics", icon: <ShoppingCartIcon /> },
  { label: "Home & Garden", icon: <StoreIcon /> },
  { label: "Kitchen & Dining", icon: <KitchenIcon /> },
  { label: "Toys & Games", icon: <SportsEsportsIcon /> },
];

const AllProducts = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(5);

  //Get current posts
  const indexOfLastProducts = currentPage * productsPerPage;
  const indexOfFirstPost = indexOfLastProducts - productsPerPage;
  const currentProducts = AllCategories.slice(
    indexOfFirstPost,
    indexOfLastProducts
  );

  //Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <Navigation />
      <Container className="mt-5 pt-2" fluid>
        <Row className="p-3" style={{ backgroundColor: "#f1f1f1" }}>
          <Breadcrumb>
            <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>Explore Proudcts</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row className="p-3 g-3 justify-content-center">
          <Col xs={12} sm={12} md={3} className="shadow">
            <MenuList>
              {categories.map((category, index) => (
                <MenuItem key={index}>
                  <ListItemIcon>{category.icon}</ListItemIcon>
                  <Typography variant="subtitle2">{category.label}</Typography>
                </MenuItem>
              ))}
            </MenuList>
          </Col>
          <Col xs={12} sm={12} md={9}>
            <Grid container spacing={2} sx={{}}>
              {currentProducts.map((item, idx) => (
                <Grid key={idx} item md={3} sm={5}>
                  <Card>
                    <CardActionArea>
                      <CardMedia
                        style={{ objectFit: "contain", width: "100%" }}
                        component="img"
                        height={128}
                        image={item.img}
                        alt="title"
                      />
                      <CardContent
                        sx={{
                          justifyContent: "flex-start",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="subtitle1"
                          component="div"
                        >
                          {item.name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                          {item.details.slice(0, 40)}...
                        </Typography>

                        <Typography variant="body2" color="text.secondary">
                          {item.offer && (
                            <span className="text-danger">-{item.offer}%</span>
                          )}{" "}
                          $ {item.price}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          List Price:{" "}
                          <span style={{ textDecoration: "line-through" }}>
                            ${item.listPrice}.00
                          </span>
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <div className="d-flex justify-content-center pt-5">
              <PaginationPage
                productsPerPage={productsPerPage}
                totalPosts={AllCategories.length}
                paginate={paginate}
              />
            </div>
          </Col>
        </Row>
        <div className="pb-5">
          <Shipping />
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default AllProducts;
