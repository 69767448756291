import {
  ButtonBase,
  Paper,
  Typography,
  Container,
  Grid,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { AllCategories } from "../..//Data/Product";

const Seller = () => {
  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });
  return (
    <Container sx={{ pt: 7 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Typography fontWeight={600} variant="h6" gutterBottom>
          OUR BEST SELLER
        </Typography>

        <div style={{ borderBottom: "1px solid #1976d2", width: "180px" }} />
      </Box>

      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {AllCategories.map((item, idx) => (
          <Grid
            item
            xs={4}
            sm={4}
            md={3}
            key={idx}
            sx={{ mt: 2, display: "flex", justifyContent: "center" }}
          >
            <Paper sx={{ p: 2, maxWidth: 345 }}>
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item>
                  <ButtonBase sx={{ width: 128, height: 128 }}>
                    <Img src={item.img} alt={item.name} />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column">
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        component="div"
                      >
                        {item.name}
                      </Typography>

                      <Typography variant="body2" color="text.secondary">
                        <span style={{ textDecoration: "line-through" }}>
                          ${item.listPrice}.00
                        </span>{" "}
                        {item.offer && (
                          <span className="text-danger">(-{item.offer}%)</span>
                        )}
                      </Typography>
                      <Typography
                        sx={{ pt: 1.5 }}
                        variant="body2"
                        color="text.secondary"
                      >
                        <b>$ {item.price}</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Seller;
