import { Box, CardActions, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, CardActionArea, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import useAuth from "../../../hooks/useAuth";
import axios from "axios";
import Pagination from "../../../Pagination/Pagination";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import CallModal from "../../../Component/CallModal";
import EmailModal from "../../../Component/EmailModal";
import ShowMoreText from "react-show-more-text";

const Product = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  // Call Modal States
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // Email Modal States
  const [openEmail, setEmail] = React.useState(false);
  const handleOpenEmail = () => {
    setEmail(true);
  };
  const handleCloseEmail = () => {
    setEmail(false);
  };

  const { user } = useAuth();
  const email = user.email;
  let unique = Math.floor(Math.random() * 100);
  let key = unique.toString();

  useEffect(() => {
    const fetchProducts = async () => {
      const res = await axios.get("https://www.vipbari.xyz/products");
      setProducts(res.data);
    };
    fetchProducts();
  }, []);

  //Get current posts
  const indexOfLastProducts = currentPage * productsPerPage;
  const indexOfFirstPost = indexOfLastProducts - productsPerPage;
  const currentProducts = products.slice(indexOfFirstPost, indexOfLastProducts);

  //Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleAddToCart = (index) => {
    const data = products[index];
    data.email = email;
    data.key = key;

    fetch("https://www.vipbari.xyz/orders", {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(data),
    });
  };

  return (
    <Container sx={{ mt: 6 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Typography fontWeight={600} align="center" variant="h5" gutterBottom>
          PRODUCT CATEGORIES
        </Typography>
        <div className="d-flex justify-content-center">
          <div
            style={{ borderBottom: "1px solid #1976d2", width: "120px" }}
          ></div>
        </div>
      </Box>
      <Grid container spacing={2} sx={{ pt: 3, justifyContent: "center" }}>
        {currentProducts.map((product, index) => (
          <Grid key={index} item md={3} data-aos="zoom-in">
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                height="180"
                image={product?.data?.img}
                alt="green iguana"
              />

              <Typography variant="h6" component="div">
                {product?.name}
              </Typography>

              <CardActionArea>
                <CardContent
                  sx={{ justifyContent: "flex-start", textAlign: "center" }}
                >
                  <ShowMoreText
                    className="text-secondary"
                    style={{ textAlign: "justify", textJustify: "interWord" }}
                    lines={4}
                    more="Show more"
                    less="Show less"
                    anchorClass="oooeeer"
                    truncatedEndingComponent={"... "}
                    expanded={false}
                  >
                    <span
                      className="text-break"
                      dangerouslySetInnerHTML={{
                        __html: product?.details,
                      }}
                    />
                  </ShowMoreText>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      bgcolor: "#0288d1",
                      color: "white",
                      marginTop: "15px",
                      padding: "4px",
                      borderRadius: "5px",
                    }}
                    variant="body2"
                    color="text.secondary"
                  >
                    {product?.data?.price} BDT
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions className="d-flex justify-content-end">
                {/* <Link to="/purchase">
                  <Button onClick={() => handleAddToCart(index)} size="small">
                    By Now
                  </Button>
                </Link> */}

                <Button
                  onClick={handleClickOpen}
                  size="small"
                  variant="contained"
                  sx={{ backgroundColor: "#00745c" }}
                  startIcon={<CallIcon />}
                >
                  Call
                </Button>
                <Button
                  onClick={handleOpenEmail}
                  size="small"
                  variant="contained"
                  sx={{ backgroundColor: "#00745c" }}
                  startIcon={<EmailIcon />}
                >
                  Email
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <div className="d-flex justify-content-center pt-5">
        <Pagination
          productsPerPage={productsPerPage}
          totalPosts={products.length}
          paginate={paginate}
        />
      </div>
      <>
        <CallModal open={open} handleClose={handleClose} />
        <EmailModal openEmail={openEmail} handleCloseEmail={handleCloseEmail} />
      </>
    </Container>
  );
};

export default Product;
