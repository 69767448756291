import { Grid, Typography, Container, Box } from "@mui/material";
import React from "react";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import p1 from "../../images/product/pcategorie.png";
import p2 from "../../images/product/pcategorie2.png";
import p3 from "../../images/product/pcategorie3.png";
import p4 from "../../images/product/pcategorie4.png";
import p5 from "../../images/product/pcategorie5.png";
import p6 from "../../images/product/pcategorie6.png";

const ProductCategories = () => {
  const productImages = [
    { image: p1, title: "pcatagorie", name: "Shoes" },
    { image: p2, title: "pcatagorie", name: "Headphones" },
    { image: p3, title: "pcatagorie", name: "Sunglasses" },
    { image: p4, title: "pcatagorie", name: "T-shirts" },
    { image: p5, title: "pcatagorie", name: "Camera" },
    { image: p6, title: "pcatagorie", name: "Kids-Cloth" },
  ];

  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Typography fontWeight={600} align="center" variant="h6" gutterBottom>
          PRODUCT CATEGORIES
        </Typography>
        <div className="d-flex justify-content-center">
          <div
            style={{ borderBottom: "1px solid #1976d2", width: "120px" }}
          ></div>
        </div>
      </Box>
      <Grid sx={{ py: 5 }} textAlign="center">
        <Swiper
          spaceBetween={50}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            425: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 0,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 0,
            },
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
        >
          {productImages.map((productImage, idx) => (
            <SwiperSlide key={idx}>
              <img src={productImage.image} alt="title" />
              <Typography fontWeight={600}>{productImage.name}</Typography>
            </SwiperSlide>
          ))}
        </Swiper>
      </Grid>
    </Container>
  );
};

export default ProductCategories;
