import { Typography, Container, Box, Button } from "@mui/material";
import { Autoplay } from "swiper";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import lc1 from "../../images/product/latest-collection-1.png";
import lc2 from "../../images/product/latest-collection-2.png";
import lc3 from "../../images/product/latest-collection-3.png";

const collection_1 = {
  backgroundImage: `url(${lc1})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
const collection_2 = {
  backgroundImage: `url(${lc2})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
const collection_3 = {
  backgroundImage: `url(${lc3})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const LatestCollection = () => {
  return (
    <Container sx={{ py: 5 }}>
      <Swiper
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        <SwiperSlide style={collection_1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 8,
            }}
          >
            <Box>
              <Typography variant="h4">Latest Collection</Typography>
              <Typography variant="subtitle1">SAVE UP TO 50%</Typography>
              <Typography variant="subtitle2">
                NEW MEN'S FASHION SHOES
              </Typography>
              <Typography variant="subtitle2">Only Select Item Only</Typography>
              <Button variant="outlined" color="error">
                SHOP NOW
              </Button>
            </Box>
          </Box>
        </SwiperSlide>

        <SwiperSlide style={collection_2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 8,
            }}
          >
            <Box>
              <Typography variant="h4">Latest Collection</Typography>
              <Typography variant="subtitle1">SAVE UP TO 50%</Typography>
              <Typography variant="subtitle2">
                NEW MEN'S FASHION SHOES
              </Typography>
              <Typography variant="subtitle2">Only Select Item Only</Typography>
              <Button variant="outlined" color="secondary">
                SHOP NOW
              </Button>
            </Box>
          </Box>
        </SwiperSlide>
        <SwiperSlide style={collection_3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              py: 8,
            }}
          >
            <Box>
              <Typography variant="h4">Latest Collection</Typography>
              <Typography variant="subtitle1">SAVE UP TO 50%</Typography>
              <Typography variant="subtitle2">
                NEW MEN'S FASHION SHOES
              </Typography>
              <Typography variant="subtitle2">Only Select Item Only</Typography>
              <Button variant="outlined" color="warning">
                SHOP NOW
              </Button>
            </Box>
          </Box>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default LatestCollection;
