import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import axios from "axios";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ShowMoreText from "react-show-more-text";
import { red } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";

const ManageProducts = () => {
  const [allProducts, setAllProducts] = useState([]);
  console.log(allProducts);

  const fetchProducts = async () => {
    const res = await axios.get(
      "https://uninterested-suit-slug.cyclic.app/products"
    );
    setAllProducts(res.data);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleDelete = (id) => {
    console.log({ id });
    const url = `https://uninterested-suit-slug.cyclic.app/product/${id}`;
    fetch(url, {
      method: "DELETE",
    }).then((res) => {
      if (res.status === 200) {
        alert("Product Removed Sucessfully ");
        fetchProducts();
      } else {
        alert("something wrong");
      }
    });
  };

  return (
    <List sx={{ bgcolor: "background.paper" }}>
      {allProducts.map((pro, idx) => (
        <Box
          key={idx}
          sx={{ display: "flex", flexDirection: "column", p: 1.5 }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <img
              className="border "
              width="150px"
              height="120px"
              src={pro?.imageUrl}
              alt={pro.name}
            />
            <StyledIconButton
              className="h-25"
              aria-label="delete"
              onClick={() => handleDelete(pro._id)}
            >
              <DeleteIcon />
            </StyledIconButton>
          </Box>
          <Box>
            <ListItemText
              primary={pro?.name}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="h6"
                    color="text.primary"
                  >
                    {pro?.price} BDT
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    <span style={{ textDecoration: "line-through" }}>
                      {pro.marketPrice}.00
                    </span>{" "}
                    {pro.discountPercent && (
                      <span className="text-danger">
                        (-{pro.discountPercent}%)
                      </span>
                    )}
                  </Typography>

                  <Typography variant="subtitle2" color="text.secondary">
                    Category &#x25CF; {pro.category}
                  </Typography>

                  <ShowMoreText
                    className="text-secondary"
                    style={{ textAlign: "justify", textJustify: "interWord" }}
                    lines={4}
                    more="Show more"
                    less="Show less"
                    anchorClass="oooeeer"
                    truncatedEndingComponent={"... "}
                    expanded={false}
                  >
                    <span className="text-break"> {pro.description}</span>
                  </ShowMoreText>
                </React.Fragment>
              }
            />

            <Divider variant="inset" component="li" />
          </Box>
        </Box>
      ))}
    </List>
  );
};

export default ManageProducts;

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.getContrastText(red[500]),
  backgroundColor: red[500],
  "&:hover": {
    backgroundColor: red[700],
  },
  "& .MuiSvgIcon-root": {
    fontSize: "1rem",
  },
  borderRadius: "10px",
}));
